import React from "react";
import { Modal } from "semantic-ui-react";
import '../../styles/modal.css';
import '../../styles/icons/css/icons.css'
import styled from "styled-components";

import ButtonDocs from '../../AccordionComponents/AccordionButtons/ButtonDocs.js'

// 💅
const ModalComponentCalculateDensity = styled.div`
  /* border-radius: 4px;
    -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.5);
    box-shadow: 0 5px 15px rgba(0,0,0,.5); */
  height: 100%;
`;

const ModalGrid = styled.div`
  max-width: 960px;
  // 📰
  display: flex;
  flex-direction: column;
  height: 100%;
  // display: grid;
  // grid-template-rows: 0.2fr 1fr 0.2fr;
  // grid-template-columns: 1fr;
  // grid-template-areas:
  //   "g_modal_header"
  //   "g_modal_content"
  //   "g_modal_footer";
`;

// MODAL GRID: HEADER
const ModalHeader = styled.div`
  grid-area: g_modal_header; // 🗺
  min-height: 85px;
  padding-top: 0;
  margin-bottom: 20px;

  border-bottom: 2px solid #e7edf0;
  margin-left: 15px;
  margin-right: 15px;

  .ModalTitle {
    font-family: Teko, "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.5;
    font-size: 30px;
    color: #141b1f;
    text-align: center;
    padding-top: 20px;
  }
  .ui.page.modals.dimmer.transition.visible.active {
    background: #000 !important;
  }
  .ui.modal.transition.visible.active {
    background: #000 !important;
  }
  .ui.modal {
    margin: 0 auto !important;
  }
`;

// MODAL GRID: CONTENT
const ModalGridContent = styled.div`
  flex-grow: 1;
  font-family: "Open Sans";
  grid-area: g_modal_content; // 🗺
  padding-left: 40px;
  padding-right: 40px;
  .es-sg-title {
    margin-bottom: 15px;
  }
  .es-i-density-calulator {
    display: grid;
    grid-template-rows: 60px 60px 60px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-flow: row;
    grid-template-areas:
    "";
  }
  .es-sg-shipment-documents{
    width: 400px;
    margin: 0 auto;
    overflow: auto;
  }
  .es-c-shipment-documents {
      .es-cg-shipment-documents {
        // 📰
        display: grid;
        margin-bottom: 15px;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 0.2fr 0.5fr 0.3fr;
        grid-template-areas:
        "cg_pdf_icon cg_title cg_icons"
        "cg_pdf_icon cg_sub_title cg_icons";

        .es-cg-pdf-icon {
          grid-area: cg_pdf_icon; // 🗺
        }
        .es-cg-title {
          grid-area: cg_title; // 🗺
          font-size: 14px;
          color: #0033a1;
        }
        .es-cg-sub-title {
          grid-area: cg_sub_title; // 🗺
          font-size: 12px;
          color: #666;
          font-weight: 400;
        }
        .es-cg-icons {
          grid-area: cg_icons; // 🗺
        }

      }
    }
`;




// MODAL GRID: FOOTER
const ModalFooter = styled.div`
  grid-area: g_modal_footer; // 🗺
  text-align: center;
  padding: 28px 37px;

  .btn{
    display: inline-flex !important;
    align-items: center;
    font-family: 'Open Sans' !important;
    background-color: #26c1fb;
    border: 0.1rem solid #26c1fb;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    height: 42px !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 6px 26px;
    text-align: center;
    -webkit-text-decoration: none;
    text-decoration: none;
    margin-right: 0px;
    text-transform: uppercase;
    white-space: nowrap;
    -webkit-transition: 0.6s cubic-bezier(0.2,0.8,0.2,1);
    transition: 0.6s cubic-bezier(0.2,0.8,0.2,1);
  }
`;

const ModalInvoice = (props) => (
  <Modal trigger={<ButtonDocs addItem>Docs</ButtonDocs>}>

      <ModalComponentCalculateDensity>
      
        <ModalGrid>
          <ModalHeader>
            <div className="ModalTitle">Shipment 33697917</div>
            <div className="Exit" />
          </ModalHeader>

          <ModalGridContent>
          <div className="es-sg-shipment-documents">
            <div className="es-sg-title">Documents</div>


            <div className="es-c-shipment-documents">
              <div className="es-cg-shipment-documents">
                <div className="es-cg-pdf-icon">
                  <svg
                    width={51}
                    height={62}
                    viewBox="0 0 51 62"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2.34286 1.01444C1.09659 1.01444 0 2.04319 0 3.23209V59.7823C0 60.967 1.09989 61.9999 2.34286 61.9999H46.8571C48.1034 61.9999 49.2 60.9712 49.2 59.7823V13.2115H44.5143H39.8286C37.318 13.6718 35.67 12.115 35.1429 9.88506V1.01444H2.34286V1.01444ZM36.3143 2.12333C36.3143 0.932709 37.1348 0.653236 38.6571 1.01451L49.2 10.994C49.6266 11.4024 49.2844 12.1028 48.0286 12.1028H38.6571C37.4596 12.1028 36.3143 11.108 36.3143 9.88512V2.12333V2.12333Z"
                      fill="#E1E2E3"
                    />
                    <path
                      d="M6.24573 28.22H12.3957C14.2557 28.22 15.1857 29.1 15.1857 30.86V37.1C15.1857 38.86 14.2557 39.74 12.3957 39.74H8.94573V47H6.24573V28.22ZM12.4857 36.68V31.28C12.4857 30.92 12.4257 30.69 12.3057 30.59C12.1857 30.47 11.9457 30.41 11.5857 30.41H8.94573V37.58H11.5857C11.9457 37.58 12.1857 37.52 12.3057 37.4C12.4257 37.28 12.4857 37.04 12.4857 36.68ZM17.6436 28.22H24.1836C26.0436 28.22 26.9736 29.1 26.9736 30.86V44.36C26.9736 46.12 26.0436 47 24.1836 47H17.6436V28.22ZM24.2436 44V31.22C24.2436 30.86 24.1836 30.63 24.0636 30.53C23.9436 30.41 23.7036 30.35 23.3436 30.35H20.3736V44.87H23.3436C23.7036 44.87 23.9436 44.82 24.0636 44.72C24.1836 44.6 24.2436 44.36 24.2436 44ZM38.1369 30.41H32.6769V36.59H36.9969V38.72H32.6769V47H29.9469V28.22H38.1369V30.41Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="es-cg-title">BOL</div>
                <div className="es-cg-sub-title">Created Mar 07, 2019</div>
                <div className="es-cg-icons" />
              </div>
            </div>

            <a href="https://res.cloudinary.com/dj1xukpnf/image/upload/v1555520639/INVOICE35010633-1.pdf" target="_blank">  
            <div className="es-c-shipment-documents">
              <div className="es-cg-shipment-documents">
                <div className="es-cg-pdf-icon">
                  <svg
                    width={51}
                    height={62}
                    viewBox="0 0 51 62"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2.34286 1.01444C1.09659 1.01444 0 2.04319 0 3.23209V59.7823C0 60.967 1.09989 61.9999 2.34286 61.9999H46.8571C48.1034 61.9999 49.2 60.9712 49.2 59.7823V13.2115H44.5143H39.8286C37.318 13.6718 35.67 12.115 35.1429 9.88506V1.01444H2.34286V1.01444ZM36.3143 2.12333C36.3143 0.932709 37.1348 0.653236 38.6571 1.01451L49.2 10.994C49.6266 11.4024 49.2844 12.1028 48.0286 12.1028H38.6571C37.4596 12.1028 36.3143 11.108 36.3143 9.88512V2.12333V2.12333Z"
                      fill="#E1E2E3"
                    />
                    <path
                      d="M6.24573 28.22H12.3957C14.2557 28.22 15.1857 29.1 15.1857 30.86V37.1C15.1857 38.86 14.2557 39.74 12.3957 39.74H8.94573V47H6.24573V28.22ZM12.4857 36.68V31.28C12.4857 30.92 12.4257 30.69 12.3057 30.59C12.1857 30.47 11.9457 30.41 11.5857 30.41H8.94573V37.58H11.5857C11.9457 37.58 12.1857 37.52 12.3057 37.4C12.4257 37.28 12.4857 37.04 12.4857 36.68ZM17.6436 28.22H24.1836C26.0436 28.22 26.9736 29.1 26.9736 30.86V44.36C26.9736 46.12 26.0436 47 24.1836 47H17.6436V28.22ZM24.2436 44V31.22C24.2436 30.86 24.1836 30.63 24.0636 30.53C23.9436 30.41 23.7036 30.35 23.3436 30.35H20.3736V44.87H23.3436C23.7036 44.87 23.9436 44.82 24.0636 44.72C24.1836 44.6 24.2436 44.36 24.2436 44ZM38.1369 30.41H32.6769V36.59H36.9969V38.72H32.6769V47H29.9469V28.22H38.1369V30.41Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="es-cg-title">Invoice</div>
                <div className="es-cg-sub-title">Created Mar 07, 2019</div>
                <div className="es-cg-icons" />
              </div>
            </div>
            </a>
            </div>

          </ModalGridContent>

          <ModalFooter>
            <a className='btn btn-default' href='#'>Cancel</a>
          </ModalFooter>
        </ModalGrid>
      </ModalComponentCalculateDensity>

  </Modal>
);
export default ModalInvoice;