import React from 'react'
import styled from 'styled-components'
import '../../styles/icons/css/icons.css';

const ButtonLoad = styled.button`
  /* Clear default button styles */
  background-color: transparent;
  color: transparent;
  border: transparent;
  border-radius: 3px;
  -webkit-appearance: none;
  padding: 0px;

  transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1); 

  .IconBox {
    width: 45px;
    padding: 6px 0px;
    border-radius: 3px;

    transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1); 
  }
  i {
    color: #0033a1;

    transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
  }
  p {
    display: none;
    font-size: 10px;
    text-transform: uppercase;
    vertical-align: middle;

    transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
  }
  /* 💅 HOVER STYLES */
  :hover .IconBox {
    color: #fff;
    background-color: #26c1fb;
    border-color: #26c1fb;

    transition: 1.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  }
  :hover i {
    color: #fff;

    transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
  }
  :hover p {
    color: #fff;
    display: block;

    transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
  }
`;


const AccordionButton = () => {
  return (
      <ButtonLoad>
        <div className="IconBox">
          <i class="fas fa-truck fa-flip-horizontal"></i>
          <p>Load</p>
        </div>
      </ButtonLoad>
  )
}
export default AccordionButton;