import React from 'react'
import styled from 'styled-components'

import PaymentSummaryTotal from './_PaymentSummaryTotal.js'
// 💅
const InvoicePaymentSummary = styled.div`
  // 📰
  display: grid;
  grid-template-rows: 100px 70px 50px 70px 50px 50px;
  grid-template-columns: 1fr;
  grid-template-areas:
  "sg_title"
  "sg_invoices_selected"
  "sg_invoice_total"
  "sg_select_payment_method"
  "sg_confirm_and_pay"
  "sg_disclaimer";

  .SideNavTitle {
    grid-area: sg_title; // 🗺
    justify-self: start;
    align-self: start;
    font-family: 'Teko', sans-serif;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.5;
    font-size: 30px;
    color: #141b1f;
    padding-top: 30px;
  }
  .InvoicesSelected {
    grid-area: sg_invoices_selected; // 🗺
    font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    font-weight: 400;
    color: #2b363b;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 15px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }

  .SelectPaymentMethod {
    grid-area: sg_select_payment_method; // 🗺
    opacity: 0.5;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .ConfirmAndPay {
    grid-area: sg_confirm_and_pay; // 🗺
    opacity: 0.5;
      .BtnConfirmAndPay {
        color: #fff;
      background-color: #3daf2c;
      border-color: #3daf2c;
      padding: 6px 16px;
      margin: 5px 0;
      height: 45px;
      text-transform: uppercase;
      border-radius: 3px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: "Open Sans";
      font-weight: 700;
      font-size: 14px;
      transition: background .125s ease,color .125s ease,border .125s ease;
      width: 100%;
      }
  }
  .Disclaimer {
    grid-area: sg_disclaimer; // 🗺
    font-family: "Open Sans";
    font-weight: 400;
    margin-top: 10px;
    font-size: 10px;
    color: #a9adaf;
    line-height: 1.42857143;
    opacity: 0.5;
      span {
        color: #0033a1;
      }
}
`;

// ⚛️
const InvoicePageComponent = (props) => {
  return (
    <InvoicePaymentSummary>
      <div className="SideNavTitle">Invoice Payment Summary</div>
      <div className="InvoicesSelected">0 Invoices Selected</div>
      <PaymentSummaryTotal
        invoiceTotal="Invoice Total"
        invoiceAmount="$0.00"
      />
      <div className="SelectPaymentMethod">
        <div className="form-group es-i-freight-class">
          <select className="form-select select-lg select-country">
            <option>Select Payment Method</option>
            <option>Add New eCheck</option>
            <option>Add New Credit Card</option>
          </select>
        </div>
      </div>
      <div className="ConfirmAndPay">
        <button className="BtnConfirmAndPay">Confirm And Pay</button>
      </div>
      <div className="Disclaimer">By selecting (Confirm and Pay) I allow Echo to initiate this payment for the amount specified above and apply the payment to the selected invoices. Also, I accept <span>Echo's Terms and Conditions</span>on additional charges if carrier cost has been increased from unexpected delivery events.</div>
    </InvoicePaymentSummary>
  )
}

export default InvoicePageComponent;