import React from 'react'
import styled from 'styled-components'

// 💅
const PaymentSummaryTotal = styled.div`
  // 📰
  grid-area: sg_invoice_total;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Open Sans";
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
  "g_total g_amount";

  .InvoiceTotal {
    grid-area: g_total; // 🗺
    justify-self: start;
    align-self: center;
    color: #666;
    font-weight: 600;
  }
  .InvoiceTotal__Amount {
    grid-area: g_amount; // 🗺
    justify-self: end;
    align-self: center;
    padding-right: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #2b363b;
  }

`;

// ⚛️
const InvoicePageComponent = (props) => {
  return (
    <PaymentSummaryTotal>

      <div className="InvoiceTotal">{props.invoiceTotal}</div>
      <div className="InvoiceTotal__Amount">{props.invoiceAmount}</div>

    </PaymentSummaryTotal>
  )
}

export default InvoicePageComponent;