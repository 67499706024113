import React from 'react'
import styled from 'styled-components'

const TableHeaderInvoicePaid = styled.div`

.es-g-shipment-table-titles {
  border-bottom: solid 1px #bbb;
  margin: 0 auto;
    max-width: 1360px;
  .es-sg-shipment-table-titles {
    font-family: "Open Sans";
    margin: 0 auto;
    max-width: 1360px;
    margin-top: 20px;
    font-size: 10px;
    font-weight: 700;
    color: #333;
    text-transform: uppercase;
    // 📰
    display: grid;
    grid-template-rows: minmax(0.5fr, 0.5fr) 0.5fr;
    grid-template-columns: 0.5fr 1fr 0.5fr 0.5fr 1.2fr 1.2fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
    "sg_input sg_title_1 sg_title_2 sg_title_3 sg_title_4 sg_title_5 sg_title_6 sg_title_7 . .";

    .sg-input {
      grid-area: sg_input; // 🗺
      margin-left: 20px;
      align-self: center;
    }
    .sg-title-1 {
      grid-area: sg_title_1; // 🗺
    }
    .sg-title-2 {
      grid-area: sg_title_2; // 🗺
    }
    .sg-title-3 {
      grid-area: sg_title_3; // 🗺
    }
    .sg-title-4 {
      grid-area: sg_title_4; // 🗺
      padding-left: 15px;
    }
    .sg-title-5 {
      grid-area: sg_title_5; // 🗺
    }
    .sg-title-6 {
      grid-area: sg_title_6; // 🗺
    }
    .sg-title-7 {
      grid-area: sg_title_7; // 🗺
    }
  }
}




`

const EchoShipMiscComponent = () => {
  return (
    <TableHeaderInvoicePaid>
      <div className="es-g-shipment-table-titles">
        <div className="es-sg-shipment-table-titles">
          <input className="sg-input" type="checkbox"></input>
          <div className="sg-title-1">INVOICE</div>
          <div className="sg-title-2">PO#</div>
          <div className="sg-title-3">ORDER#</div>
          <div className="sg-title-4">ORIGIN</div>
          <div className="sg-title-5">DESTINATION</div>
          <div className="sg-title-6">AGING</div>
          <div className="sg-title-7">AMOUNT DUE</div>
        </div>
      </div>
    </TableHeaderInvoicePaid>  
  )
}

export default EchoShipMiscComponent;