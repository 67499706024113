import React from 'react'
import styled from 'styled-components'

import '../styles/spectre.css';

const InputDropdownInvoices = styled.div`

.form-group.es-i-template {

  font-family: "Open Sans";


  label.form-label {
    margin-bottom: 5px;
    font-weight: 700;
    color: #666;
    font-size: 14px;
    font-weight: 600;
    width: 70%;

    span {
      color: #26c1fb;
    }
  }
}

`;

const EchoShipInput = (props) => {
  return (
    <InputDropdownInvoices>
        <div className="form-group es-i-template"
        style={{
          width: "80%"
        }}
        >
        <label className="form-label" htmlFor="input-example-1">
          {props.labelTitle}
        </label>
        <select className="form-select select-lg">
          <option>Payment Date (Newest to Oldest)</option>
          <option>Payment Date (Oldest to Newest)</option>
          <option>Amount Paid (Highest to Lowest)</option>
          <option>Amount Paid (Lowest to Highest)</option>
        </select>
        </div>
    </InputDropdownInvoices>
  )
}

export default EchoShipInput;