import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby';

const TertiaryNavInvoice = styled.div`

.es-c-tertiary-nav {
  // 🎛
  // 🎨
  background-color: #fff;
  border-bottom: solid 1px #bbb;
  // 🖋
  font-family: 'Open Sans';
  .es-cg-tertiary-nav {
    // 📰
    padding-bottom: 10px;
    padding-top: 10px;
    margin: 0 auto;
    max-width: 1360px;
    min-height: 22px;

    display: grid;
    align-items: start;
    justify-self: start;
    grid-template-rows: 20px;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 1fr 1fr;
    grid-template-areas:
    "tab_item tab_item tab_item tab_item . .";

    .es-tab-item a {
      grid-area: tab_item; // 🗺
      text-decoration: none;
      justify-self: start;
      color: #666;
      cursor: pointer;
      font-size: 14px;
      padding-bottom: 9px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .es-tab-item a.active {
  border-bottom: solid 3px #0033a1;
  color: #0033a1 !important;
}
}
`

const NavComponentTertiaryInvoice = () => {
  return (
    <TertiaryNavInvoice>
        <div className="es-pg-tertiary-nav">
          <div className="es-c-tertiary-nav">
            <div className="es-cg-tertiary-nav">
              <div className="es-tab-item">
                <a href="/" className="active">Open Invoices</a>
              </div>
              <div className="es-tab-item">
                <a href="/">
                  <Link to="/Invoices/PaidInvoice/">
                    Paid Invoices
                  </Link>
                </a>
              </div>
            </div>
          </div>
        </div>
    </TertiaryNavInvoice>
  )
}

export default NavComponentTertiaryInvoice;